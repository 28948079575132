import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/home/runner/work/dfs/dfs/packages/dfs-app/node_modules/@spear-ai/ui/dist/components/toast.js");
;
import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/home/runner/work/dfs/dfs/packages/dfs-app/node_modules/@vercel/analytics/dist/next/index.mjs");
;
import(/* webpackMode: "eager" */ "/home/runner/work/dfs/dfs/packages/dfs-app/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Domine\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-family-serif\"}],\"variableName\":\"serif\"}");
;
import(/* webpackMode: "eager" */ "/home/runner/work/dfs/dfs/packages/dfs-app/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-family-sans-serif\"}],\"variableName\":\"sansSerif\"}");
;
import(/* webpackMode: "eager", webpackExports: ["AppProviders"] */ "/home/runner/work/dfs/dfs/packages/dfs-app/src/app/providers.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/dfs/dfs/packages/dfs-app/src/app/style.css");
